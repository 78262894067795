import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BsJustify } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalTitle, ModalBody } from "react-bootstrap";
import ChangePassword from "../../content/UpdateUserForm.js";
import { HiMenuAlt1 } from "react-icons/hi";

import {
  Image,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Button,
  Flex,
  Icon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Avatar,
} from "@chakra-ui/react";
import { RiLogoutBoxLine } from "react-icons/ri";
import { GoPeople } from "react-icons/go";

function Header({ OpenSidebar, isOpen }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const user = localStorage.getItem("username");
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const employeeId = localStorage.getItem("id");
  const profilePicture = localStorage.getItem("profile_picture");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logoutSubmit = async () => {
    try {
      localStorage.clear();

      window.location.href = "/";
    } catch (error) {
      console.error("There was an error during logout:", error);
      // Handle error, show error message, or perform any other action
    }
  };

  const handleMenuClick = (action) => {
    if (action === "logout") {
      handleLogout();
    } else if (action === "changePass") {
      handleChangePass();
    }
    else if (action === "profile") {
      handleProfile();
    }
  };

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    setLoading(true); // Set loading state to true to blur the content

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: true,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure want to \n Log Out?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setTimeout(() => {
            logoutSubmit();
            setLoading(false); // Set loading state to false after logout operation completes
          }, 2000);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setLoading(false); // Set loading state to false if user cancels the operation
        }
      });
  };

  const handleProfile = () => {
    const url = `/profile/${employeeId}`;
    window.location.href = url;
  };

  const handleChangePass = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <header
      className={`header d-flex items-center justify-between z-20 px-4 sm:px-6 md:px-8 lg:px-10 py-3 md:py-4 lg:py-5 ${isOpen ? "w-full" : "w-full"}`}
      style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
      <div className="header-left">
        <HiMenuAlt1
          className="icon w-5 h-5 text-blue-600"
          onClick={OpenSidebar} />
      </div>
      <div className="header-right flex items-center">
      <Menu>
            <MenuButton
              as={Button}
              colorScheme="transparent"
              color="#238FBA"
              borderColor="#238FBA"
              border={{ base: 0, md: "2px" }}
              borderRadius="full"
              p={{ base: 0, sm: 6 }}
              _hover={{ color: "#1a6d8e" }}
              _active={{ color: "#1a6d8e" }}
            >
              <Flex direction="row" alignItems="center" gap="3">
                {/* <Icon as={FaUserCircle} boxSize={{ base: 7, md: 6 }} /> */}
                <Avatar
                border={"1px"}
                borderColor={"blue.800"}
                  src={
                    profilePicture
                      ? `${process.env.REACT_APP_API_URL}/${profilePicture}`
                      : "https://zultimate.com/wp-content/uploads/2019/12/default-profile.png"
                  }
                  w={8}
                  h={8}
                />
                {/* Use Chakra UI's responsive display for the Text component */}
                <Text
                  display={["none", "none", "block"]}
                  fontWeight="400"
                  fontSize="md"
                >
                  {user}
                </Text>
              </Flex>
            </MenuButton>
            <MenuList>
              {/* <MenuItem icon={<GoPeople/>} onClick={() => handleMenuClick("profile")}>
                Profile
              </MenuItem> */}
              {/* {roleId == 2 && (
                <MenuItem icon={<HiOutlineBuildingOffice2/>} onClick={() => handleMenuClick("profile")}>
                My Company
              </MenuItem>
              )}
              
              <MenuDivider/> */}
              {/* <MenuItem>
              Help
              </MenuItem> */}
              <MenuItem icon={<RiLogoutBoxLine/>} onClick={() => handleMenuClick("logout")}>
                Log Out
              </MenuItem>
            </MenuList>
          </Menu>

        <Modal show={showModal} onHide={handleCloseModal} size="md" centered>
          <ModalHeader>
            <ModalTitle>{user}'s Profile</ModalTitle>
          </ModalHeader>
          <Modal.Body>
            <ChangePassword onClose={handleCloseModal} />
          </Modal.Body>
        </Modal>
      </div>
    </header>
  );
}

export default Header;
