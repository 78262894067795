import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MonthPicker = ({ onChange }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  return (
    <DatePicker
      selected={selectedMonth}
      placeholderText="MM/yyyy"
      onChange={(date) => {
        setSelectedMonth(date);
        onChange(date);
      }}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      className='w-full form-control'
    />
  );
};


export default MonthPicker;
