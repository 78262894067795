import React from "react";
import { Spinner, Box, Flex, Text, Image } from "@chakra-ui/react";
import LogoKTSymbol from "./../../assets/logoKT - Symbol.png";

const LoadingOverlay = () => {
  return (
  <Box h="100vh" display={"center"}>
      <Flex
        h={"full"}
        w={"100vw"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        bg="white" opacity={0.6}
        gap={2}
      >
        <Spinner
          thickness="10px"
          speed="0.65s"
          // emptyColor="gray.200"
          color="blue.500"
          width="3em"
  height="3em"
        />
        {/* <Image src={LogoKTSymbol} w={{ base: "3em", sm: "3em" }} /> */}
        <Text fontWeight={"300"}>Redirecting . . .</Text>
      </Flex>
    </Box>
  );
};

export default LoadingOverlay;
