import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginPageImg from "../assets/Group-140.png";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../components/general/footer";
import {
  Card,
  Box,
  Flex,
  useToast,
  Spinner,
  Button,
  Link,
  Input,
  FormLabel,
  FormControl,
  InputGroup,
  InputRightElement,
  FormHelperText,
  FormErrorMessage,
  Image,
  Text,
  Stack,
} from "@chakra-ui/react";
import PulseLoader from "react-spinners/PulseLoader";
import { jwtDecode } from "jwt-decode";
import LogoKT from "../assets/Logo - Admin.png";
import LoadingOverlay from "../components/general/LoadingOverlay";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const [input, setInput] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const loginAPI = process.env.REACT_APP_API_URL + "/api/admin/login/";
  // const loginAPI = "http://192.168.3.183:8000/api/login";
  useEffect(() => {
    const login = localStorage.getItem("login");
    if (login) {
      navigate("/dashboard");
    }

    const loginStatus = localStorage.getItem("loginStatus");
    if (loginStatus) {
      setError(loginStatus);
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      });
    }

    setTimeout(() => {
      setMsg("");
    });
  }, [navigate]);

  const handleInputChange = (e, type) => {
    setError("");
    switch (type) {
      case "username":
        setUsername(e.target.value);
        if (e.target.value === "") {
          setError("Kolom Username tidak boleh kosong");
        }
        break;
      case "password":
        setInput(e.target.value);
        setPassword(e.target.value);
        if (e.target.value === "") {
          setError("Password tidak boleh kosong");
        }
        break;
      default:
    }
  };

  const loginSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(loginAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });
      if (!response.ok) {
        let errorMessage = "";

        switch (response.status) {
          case 400:
            errorMessage = "The server could not understand the request.";
            break;
          case 401:
            errorMessage =
              "Username or Password is incorrect. Please try again.";
            break;
          case 429:
            errorMessage =
              "The user has sent too many requests in a given amount of time.";
            break;
          case 500:
            errorMessage =
              "The server has encountered a situation right now. Please try again later.";
            break;
          default:
            errorMessage = `HTTP error! Status: ${response.status}`;
        }

        throw new Error(errorMessage);
      }

      const data = await response.json();
      if (data.status === "error") {
        toast({
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-center",
          description: data.message,
        });
      } else {
        const userToken = data.token;
        const checkJobs = data.jobs;
        const checkTeam = data.teams;
        const checkMessage = data.additional_message;
        const decodedToken = jwtDecode(userToken);
        const profilePicture = data.profile_picture;

        const userId = decodedToken.data.id;
        const roleId = decodedToken.data.role_id;
        const jobsId = decodedToken.data.jobs_id;
        const teamId = decodedToken.data.team_id;
        const companyId = decodedToken.data.company_id;

        localStorage.setItem("login", true);
        localStorage.setItem("username", username);
        localStorage.setItem("token", userToken);
        localStorage.setItem("id", userId);
        localStorage.setItem("role_id", roleId);
        localStorage.setItem("jobs_id", jobsId);
        localStorage.setItem("team_id", teamId);
        localStorage.setItem("company_id", companyId);
        localStorage.setItem("jobs", checkJobs);
        localStorage.setItem("teams", checkTeam);
        localStorage.setItem("additional_message", checkMessage);
        localStorage.setItem(
          "access_permissions",
          JSON.stringify(data.access_permissions)
        );
        localStorage.setItem("profile_picture", profilePicture);
      }

      setIsLoading(true);
      navigate("/dashboard");
    } catch (error) {
      setIsLoading(false);
      toast({
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-center",
        description: error.message,
      });
      console.error("There was an error!", error);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      loginSubmit();
    }
  };

  return (
    <Box h="100vh" bg="white">
      <Flex
        h={"100vh"}
        p={0}
        m={0}
        direction="column"
        bgImage={`url(${LoginPageImg})`}
        bgPos="bottom"
        bgSize="cover"
        justifyContent="center"
        alignItems="center"
      >
        <Card
          w={{ base: "20em", sm: "25em" }}
          bg="white"
          border={"lg"}
          borderRadius={"lg"}
          alignItems={"center"}
          justifyContent={"center"}
          p={"20px"}
        >
          <Flex
            direction="column"
            gap={4}
            w={"full"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Image src={LogoKT} w={{ base: "15em", sm: "15em" }} />
            <FormControl>
              <FormLabel>User Name</FormLabel>
              <Input
                type="text"
                id="username"
                w="full"
                h="50px"
                placeholder="Masukkan Username"
                value={username}
                onChange={(e) => handleInputChange(e, "username")}
                onKeyDown={(e) => handleKeyDown(e)}
                isRequired
              />
            </FormControl>
            <Stack w={"full"}>
              <FormControl>
                <FormLabel className="text-normal">Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    type={show ? "text" : "password"}
                    id="password"
                    placeholder="Masukkan Kata Sandi"
                    w="full"
                    h="50px"
                    value={password}
                    onChange={(e) => handleInputChange(e, "password")}
                    onKeyDown={(e) => handleKeyDown(e)}
                    isRequired
                  />
                  <InputRightElement
                    width="4.5rem"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      h="2rem"
                      size="sm"
                      onClick={handleClick}
                      mt="0.5rem"
                    >
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {/* <Link fontSize={"sm"} fontWeight={"500"} onClick={() => {
              navigate("/forgot-password");
            }}>Lupa Password?</Link> */}
            </Stack>
            <Button
              type="submit"
              colorScheme={isLoading ? "#074173" : "blue"}
              w="full"
              borderRadius="md"
              isLoading={isLoading}
              isDisabled={isLoading || !username}
              onClick={loginSubmit}
              className={isLoading ? "cursor-not-allowed" : ""}
              bg={isLoading ? "#074173" : "#1679AB"}
              _hover={{ bg: "#284b69" }}
              _active={{ bg: "074173" }}
              color="white"
            >
              <Box
                w="full"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {isLoading ? (
                  <>
                    <Box as="span" mr="2">
                      Redirecting
                    </Box>
                    <Spinner color="white" />
                  </>
                ) : (
                  "Login"
                )}
              </Box>
            </Button>
            {/* <Box style={{ position: "relative", bottom: 0 }}>
              <Text className="text-sm text-normal">
                Belum punya akun?{" "}
                <Link
                  color="blue.600"
                  textDecoration="underline"
                  fontWeight="500"
                  onClick={() => {
                    navigate("/register/free");
                  }}
                >
                  Daftar sekarang!
                </Link>
              </Text>
            </Box> */}
          </Flex>
        </Card>
      </Flex>
    </Box>
  );
};

export default Login;
