import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IoIosSpeedometer } from "react-icons/io";
import {
  BsFillArrowLeftSquareFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";
import { PiAirplaneLandingLight } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { MdAutoGraph } from "react-icons/md";
import { CgWebsite } from "react-icons/cg"; 
import logoKT from "../../assets/logoKT - transparent.png";
import logoKTSymbol from "../../assets/logoKT - Symbol.png";
import { List, ListItem, ListIcon, Box, Text, Flex } from "@chakra-ui/react";

function Sidebar({ isOpen, isTransitioning, handleClick }) {

  const location = useLocation();
  const isActiveLink = (href) => {
    return location.pathname === href;
  };

  useEffect(() => {
    const handlePageChange = () => {
      localStorage.removeItem("selectedContentType");
    };

    // Add event listener to remove contentType item from localStorage when clicking a sidebar link
    document.querySelectorAll(".sidebar-list-item a").forEach((link) => {
      link.addEventListener("click", handlePageChange);
    });

    return () => {
      // Remove event listener when component unmounts
      document.querySelectorAll(".sidebar-list-item a").forEach((link) => {
        link.removeEventListener("click", handlePageChange);
      });
    };
  }, []);

  const listItems = [
    {
      href: "/dashboard",
      icon: IoIosSpeedometer,
      text: "Dashboard",
    },
    {
      href: "/manage-user",
      icon: PiUsersThree,
      text: "Kelola Pengguna",
    },
    // {
    //   href: "/landing-page",
    //   icon: CgWebsite,
    //   text: "Kelola Landing Page",
    // },
  ];
  
  return (
    <aside
      id="sidebar"
      className={`h-full flex flex-col ${isOpen ? "w-full" : "w-14"}`}
      style={{
        overflowX: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
      }}
    >
      <div className="sidebar-title">
        <button onClick={handleClick}>
          <div
            className={`icon w-5 h-5 transform transition-transform duration-400 ${
              isTransitioning ? "rotate-45" : ""
            }`}
          >
            {isOpen ? (
              <BsFillArrowLeftSquareFill />
            ) : (
              <img src={logoKTSymbol} alt="Logo" />
            )}
          </div>
        </button>
        {isOpen && (
          <a href="/dashboard">
            <img
              src={logoKT}
              className="w-20 md:w-40 lg:w-45 ml-3 md:ml-5"
              alt="Logo"
            />
          </a>
        )}
      </div>
      <List spacing={3} mx="2">
        {listItems.map(
          (item, index) => (
              <ListItem
                key={index}
                p="12px"
                justifyItems="center"
                m="0 2 2 2"
                borderRadius="full"
                className={`sidebar-list-item ${
                  isActiveLink(item.href) ? "active" : ""
                }`}
              >
                <a
                  className="flex items-center"
                  href={item.href}
                >
                  <ListIcon as={item.icon} fontSize="md" />
                  {isOpen && <Text fontSize="md" fontWeight={"500"}>{item.text}</Text>}
                </a>
              </ListItem>
            )
        )}
      </List>
    </aside>
  );
}

export default Sidebar;
