import React from "react";
import { Button, Card, Box, Text, Flex } from "@chakra-ui/react";
import {
  FaEye,
  FaCheck,
  FaTimes,
  FaHome,
  FaAward,
  FaBriefcase,
} from "react-icons/fa";

function UserTable({ data }) {
  // Utility function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const getVerifyText = (verified) => {
    switch (verified) {
      case true:
        return "Premium";
      case false:
        return "Free Trial";
      case "active":
        return "Active";
      case "inactive":
        return "Inactive";
      default:
        return "Unknown";
    }
  };

  const getBackgroundColor = (verified) => {
    switch (verified) {
      case true:
        return "#DAA520";
      case false:
        return "gray.300";
      case "active":
        return "green.400";
      case "inactive":
        return "red.400";
      default:
        return "Unknown";
    }
  };

  const getTextColor = (verified) => {
    switch (verified) {
      case true:
        return "#B8860B";
      case false:
        return "#2F4F4F";
      case "active":
        return "white";
      case "inactive":
        return "white";
      default:
        return "Unknown";
    }
  };

  const getShimmerClass = (verified) => {
    return verified ? 'shimmer-gold' : 'shimmer-silver';
  };

  return (
    <Box className="col w-2/3 bg-white rounded-lg p-2 min-h-[50vh] gap-2">
      <Flex w="full" justifyContent="space-between">
        <Text
          fontSize={"xl"}
          className="inline-block rounded-lg text-black font-semibold bg-[#FF8787] p-2"
        >
          Overview Registrasi Pelanggan
        </Text>
        <Button
          boxShadow={"md"}
          bg={"gray.500"}
          color={"white"}
          _hover={{ bg: "gray.400" }}
          leftIcon={<FaEye />}
          onClick={() => (window.location.href = "/manage-user")}
        >
          Lihat Detail
        </Button>
      </Flex>

      <Box
        p={4}
        overflowX={"auto"}
      >
        <table className="table table-hover rounded-xl" id="list">
          <thead className="">
            <tr className="font-medium text-[#1B6E8F] align-middle">
              <th>No</th>
              <th className=" text-center">Nama Pelanggan</th>
              <th className=" text-center">Nama Perusahaan</th>
              <th className=" text-center">Email Perusahaan</th>
              <th className=" text-center">Tipe Akun</th>
              <th className=" text-center">Total Anggota</th>
              <th className=" text-center">Tanggal Bergabung</th>
              <th className=" text-center">Tanggal Berakhir</th>
              <th className=" text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr className="align-middle" key={index}>
                  <td>{index + 1}</td>
                  <td className="text-center">{item.employee_name ?? "-"}</td>
                  <td className="text-center">{item.company_name}</td>
                  <td className="text-center">{item.company_email}</td>
                  <td className="text-center">
                    <Text
                      // bg={getBackgroundColor(item.verified)}
                      p={2}
                      borderRadius={"md"}
                      display={"inline-flex"}
                      fontWeight={"600"}
                      // color={getTextColor(item.verified)}
                      color={"black"}
                      className={getShimmerClass(item.verified)}
                      boxShadow={"md"}
                    >
                      {getVerifyText(item.verified)}
                    </Text>
                  </td>
                  <td className="text-center">
                    {item.total_employee_registered} / {item.total_employee}
                  </td>
                  <td className="text-center">{formatDate(item.created_at)}</td>
                  <td className="text-center">
                    {item.company_expired
                      ? formatDate(item.company_expired)
                      : "-"}
                  </td>
                  <td className="text-center">
                    <Text
                      bg={getBackgroundColor(item.status)}
                      p={2}
                      borderRadius={"md"}
                      display={"inline-flex"}
                      fontWeight={"600"}
                      color={getTextColor(item.status)}
                    >
                      {getVerifyText(item.status)}
                    </Text>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default UserTable;
