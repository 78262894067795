import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";
import {
  FaEye,
  FaCheck,
  FaTimes,
  FaPlus,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { HiPlus } from "react-icons/hi";
import {
  Card,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Box,
  Text,
  ButtonGroup,
  Flex,
  Select,
  NumberInput,
  Stack,
  Divider,
} from "@chakra-ui/react";
import SearchInput from "../components/general/searchInput";
import DatePicker from "../components/controls/DatePicker";
import MonthPicker from "../components/controls/MonthPicker";
import EmployeeDropdown from "../components/controls/employeeDropdown";
import UserDetail from "./userDetail";
import { GrPowerReset } from "react-icons/gr";

const ManageUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const handleClick = (companyId) => {
    onOpenDrawer();
    setCompanyId(companyId);
  };

  const currentDate = new Date();
  const currentYearMonth = `${currentDate.getFullYear()}-${String(
    currentDate.getMonth() + 1
  ).padStart(2, "0")}`;
  const [searchQuery, setSearchQuery] = useState("");
  const [itemPerPage, setItemPerPage] = useState(10);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedUntilDate, setSelectedUntilDate] = useState("");
  const [searchedFromDate, setSearchedFromDate] = useState("");
  const [searchedUntilDate, setSearchedUntilDate] = useState("");
  const [selectedType, setSelectedType] = useState();
  const [searchedType, setSearchedType] = useState("");
  const [customer, setCustomer] = useState([]);

  const fetchCustomer = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/manage-company`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const customer = response.data.companies;
      setCustomer(customer);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const [overlay, setOverlay] = React.useState(<OverlayOne />);

  const [code, setCode] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handleGenerateCode = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/code`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setCode(response.data.data.code);
    } catch (error) {
      console.error("Failed to generate code: ", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "entry") {
      setItemPerPage(value);
    } else if (name === "tipe") {
      const valueAsBoolean =
        value === "true" ? true : value === "false" ? false : "";
      setSelectedType(valueAsBoolean);
    }
  };

  const getVerifyText = (verified) => {
    switch (verified) {
      case true:
        return "Premium";
      case false:
        return "Free Trial";
      case "active":
        return "Active";
      case "inactive":
        return "Inactive";
      default:
        return "Unknown";
    }
  };

  const getBackgroundColor = (verified) => {
    switch (verified) {
      case true:
        return "#2C4C9C";
      case false:
        return "gray.300";
      case "active":
        return "green.400";
      case "inactive":
        return "red.400";
      default:
        return "Unknown";
    }
  };

  const getTextColor = (verified) => {
    switch (verified) {
      case true:
        return "#B8860B";
      case false:
        return "#2F4F4F";
      case "active":
        return "white";
      case "inactive":
        return "white";
      default:
        return "Unknown";
    }
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
  };

  const filteredData =
    customer &&
    customer.filter((data) => {
      const searchQueryLower = searchQuery.toLowerCase();

      const nameMatches = data.employee_name
        ? data.employee_name.toLowerCase().includes(searchQueryLower)
        : false;

      const companyNameMatches = data.company_name
        ? data.company_name.toLowerCase().includes(searchQueryLower)
        : false;

      const typeMatches =
        typeof data.verified === "boolean"
          ? (data.verified ? "premium" : "free trial").includes(
              searchQueryLower
            )
          : false;

      return nameMatches || companyNameMatches || typeMatches;
    });

  const UsedData = filteredData.filter((data) => {
    // Convert search dates to Date objects if they are not already
    const JoinedDate = new Date(data.created_at);
    const ExpiredDate = new Date(data.company_expired);

    // Convert search dates to Date objects if they are not already
    const fromDate = searchedFromDate ? new Date(searchedFromDate) : null;
    const untilDate = searchedUntilDate ? new Date(searchedUntilDate) : null;

    // Helper function to check if a date is within the specified month and year
    const isWithinMonthAndYear = (date, month, year) => {
      if (!date || month === null || year === null) return true;
      return date.getMonth() + 1 === month && date.getFullYear() === year;
    };

    // Check if `searchedFromDate` and `searchedUntilDate` are specified
    const fromMonth = fromDate ? fromDate.getMonth() + 1 : null;
    const fromYear = fromDate ? fromDate.getFullYear() : null;
    const untilMonth = untilDate ? untilDate.getMonth() + 1 : null;
    const untilYear = untilDate ? untilDate.getFullYear() : null;

    // Filter by Verified
    const matchedType = searchedType === "" || data.verified === searchedType;

    // Filter by Joined Date
    const isAfterFromDate = fromDate
      ? isWithinMonthAndYear(JoinedDate, fromMonth, fromYear)
      : true;

    // Filter by Expired Date
    const isBeforeUntilDate = untilDate
      ? isWithinMonthAndYear(ExpiredDate, untilMonth, untilYear)
      : true;

    // Return true if all conditions are met
    return matchedType && isAfterFromDate && isBeforeUntilDate;
  });

  const handleSearchClick = () => {
    setSearchedFromDate(selectedFromDate);
    setSearchedUntilDate(selectedUntilDate);
    setSearchedType(selectedType);
  };

  const resetFilters = () => {
    setSearchedType("");
    setSearchedFromDate("");
    setSearchedUntilDate("");
    setSelectedFromDate("");
    setSelectedUntilDate("");
    setSelectedType("");
    fetchCustomer(); // Fetch or show all data
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const itemsPerPage = itemPerPage;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(customer.length / itemsPerPage);

  const indexOfFirstItem = (currentPage - 1) * itemsPerPage;
  const indexOfLastItem = indexOfFirstItem + itemsPerPage;
  const currentItems = UsedData.slice(
    indexOfFirstItem,
    indexOfLastItem
  ).reverse();

  const renderData = () => {
    if (!currentItems || currentItems.length === 0) {
      return (
        <tr>
          <td colSpan="11" className="text-center">
            No data
          </td>
        </tr>
      );
    }

    return currentItems.map((data, index) => {
      const itemNumber = indexOfFirstItem + index + 1;
      return (
        <tr className="align-middle" key={index}>
          <td>{itemNumber}</td>
          <td className="text-center">
            {data.employee_name ? data.employee_name : "Tidak ada Super Admin"}
          </td>
          <td className="text-center">{data.company_name}</td>
          <td className="text-center">{data.company_email}</td>
          <td className="text-center">
            <Text
              // bg={getBackgroundColor(data.verified)}
              p={2}
              borderRadius={"md"}
              display={"inline-flex"}
              fontWeight={"600"}
              color={getTextColor(data.verified)}
              className={getShimmerClass(data.verified)}
            >
              {getVerifyText(data.verified)}
            </Text>
          </td>
          <td className="text-center">
            {data.total_employee_registered} / {data.total_employee}
          </td>
          <td className="text-center">
            {data.created_at ? formatDate(data.created_at) : "-"}
          </td>
          <td className="text-center">
            {data.company_expired
              ? formatDate(data.company_expired)
              : "Belum ditentukan"}
          </td>
          <td className="text-center">
            <Text
              bg={getBackgroundColor(data.status)}
              p={2}
              borderRadius={"md"}
              display={"inline-flex"}
              fontWeight={"600"}
              color={getTextColor(data.status)}
            >
              {getVerifyText(data.status)}
            </Text>
          </td>
          <td>
            <Button
              size={"sm"}
              colorScheme="blue"
              onClick={() => handleClick(data.id)}
            >
              <FaEye />
            </Button>
          </td>
        </tr>
      );
    });
  };

  const handlePageChange = (page) => {
    const newPage = Math.max(1, Math.min(totalPages, page));
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const maxDisplayedPages = 5;
    const halfMaxDisplayedPages = Math.floor(maxDisplayedPages / 2);

    let startPage, endPage;

    if (currentPage <= halfMaxDisplayedPages + 1) {
      startPage = 1;
      endPage = Math.min(totalPages, maxDisplayedPages);
    } else if (currentPage >= totalPages - halfMaxDisplayedPages) {
      startPage = Math.max(1, totalPages - maxDisplayedPages + 1);
      endPage = totalPages;
    } else {
      startPage = currentPage - halfMaxDisplayedPages;
      endPage = startPage + maxDisplayedPages - 1;
    }

    return (
      <ul className="pagination">
        {currentPage > 1 && (
          <li className="page-item">
            <button
              className="page-link py-[10px]"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <FaChevronLeft />
            </button>
          </li>
        )}
        {startPage > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => handlePageChange(1)}>
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <li key={number} className="page-item">
            <button
              className={`page-link ${currentPage === number ? "active" : ""}`}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          </li>
        ))}
        {endPage < totalPages - 1 && (
          <li className="page-item disabled">
            <span className="page-link">...</span>
          </li>
        )}
        {endPage < totalPages && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => handlePageChange(totalPages)}
            >
              {totalPages}
            </button>
          </li>
        )}
        {currentPage < totalPages && (
          <li className="page-item">
            <button
              className="page-link py-[10px]"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaChevronRight />
            </button>
          </li>
        )}
      </ul>
    );
  };

  const getShimmerClass = (verified) => {
    return verified ? "shimmer-gold" : "shimmer-silver";
  };

  return (
    <Box p={{ base: 5, md: 7 }} className="main-container flex-col flex gap-3">
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        className="main-title"
      >
        <Text
          color={"black"}
          fontWeight={"700"}
          fontSize={{ base: "xl", md: "3xl" }}
        >
          Kelola Pelanggan
        </Text>
        <Button
          bg={"gray.700"}
          leftIcon={<HiPlus />}
          boxShadow={"lg"}
          color={"white"}
          onClick={() => {
            handleGenerateCode();
            setOverlay(<OverlayOne />);
            onOpenModal();
          }}
          size={{ base: "sm", md: "md" }}
        >
          Generate Code
        </Button>

        <Modal isCentered isOpen={isModalOpen} onClose={onCloseModal}>
          {overlay}
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="flex-col flex gap-3">
                <div className="flex-row flex gap-3 items-center text-normal">
                  Code Generated for Premium Customer{" "}
                  {isCopied ? (
                    <span>Copied</span>
                  ) : (
                    <MdOutlineContentCopy
                      onClick={handleCopy}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
                <div className="text-center font-semibold">
                  <p className="text-3xl">{code}</p>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button boxShadow={"lg"} onClick={onCloseModal}>
                Batal
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box
        boxShadow="base"
        p={4}
        rounded="md"
        bg="white"
        style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)" }}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          w="full"
          alignItems={"center"}
          color={"black"}
          gap={3}
          justifyContent={{ base: "start", md: "space-between" }}
        >
          <Flex gap={3} direction={{ base: "column", md: "row" }}>
            <Flex gap={3}>
              <Stack>
                <Text fontWeight={"600"}>Bulan Bergabung</Text>
                <MonthPicker
                  className="text-black rounded-lg w-full form-control"
                  selectedDate={selectedFromDate}
                  onChange={(date) => setSelectedFromDate(date)}
                />
              </Stack>
              <Stack>
                <Text fontWeight={"600"}>Bulan Akun Berakhir</Text>
                <MonthPicker
                  className="text-black rounded-lg w-full form-control"
                  selectedDate={selectedUntilDate}
                  onChange={(date) => setSelectedUntilDate(date)}
                />
              </Stack>
              <Stack>
                <Text fontWeight={"600"}>Tipe Akun</Text>
                <Select
                  name="tipe"
                  onChange={handleChange}
                  value={selectedType}
                >
                  <option value="">Pilih Tipe</option>
                  <option value="true">Premium</option>
                  <option value="false">Free Trial</option>
                </Select>
              </Stack>
            </Flex>
            <Flex gap={3}>
              <Stack>
                <Box display={{ base: "none", md: "block" }} h={6}></Box>
                <Button
                  leftIcon={<CiSearch />}
                  bg="black"
                  color={"white"}
                  onClick={() => handleSearchClick()}
                  // isDisabled={searchedFromDate === "" || searchedUntilDate === "" || searchedType === ""}
                >
                  Telusuri
                </Button>
              </Stack>
              <Stack>
                <Box display={{ base: "none", md: "block" }} h={6}></Box>
                <Button
                  onClick={resetFilters}
                  colorScheme="gray"
                  leftIcon={<GrPowerReset />}
                >
                  Reset
                </Button>
              </Stack>
            </Flex>
          </Flex>
          <Stack justifyContent={"flex-start"}>
            <Box display={{ base: "none", md: "block" }} h={6}></Box>
            <SearchInput
              onSearchChange={handleSearchChange}
              className="form-control w-[300px] md:w-[460px] h-[40px]  rounded-full"
            />
          </Stack>
        </Flex>
      </Box>

      <Box
        boxShadow="base"
        p={4}
        rounded="md"
        bg="white"
        style={{ boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)" }}
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        overflowX={"auto"}
      >
        <table
          className="table table-hover rounded-xl min-w-[1000px]"
          id="list"
        >
          <thead className="">
            <tr className="font-medium text-[#1B6E8F] align-middle">
              <th>No</th>
              <th className=" text-center">Nama Pelanggan</th>
              <th className=" text-center">Nama Perusahaan</th>
              <th className=" text-center">Email Pelanggan</th>
              <th className=" text-center">Tipe Akun</th>
              <th className=" text-center">Total Anggota</th>
              <th className=" text-center">Tanggal Bergabung</th>
              <th className=" text-center">Tanggal Berakhir</th>
              <th className=" text-center">Status</th>
              <th className=" text-center">Aksi</th>
            </tr>
          </thead>
          <tbody>{renderData()}</tbody>
        </table>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box className="mt-2 text-black">
            Showing {indexOfFirstItem + 1} to{" "}
            {indexOfLastItem > UsedData.length
              ? UsedData.length
              : indexOfLastItem}{" "}
            of {UsedData.length} entries
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={3}
            alignItems={"center"}
          >
            <Flex
              w="full"
              color={"black"}
              gap={3}
              direction={"row"}
              alignItems={"center"}
            >
              <Text>Show</Text>
              <Select
                name="entry"
                w="5em"
                defaultValue={"10"}
                onChange={handleChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </Select>
              <Text>Result per Page</Text>
            </Flex>
            <Box>{renderPagination()}</Box>
          </Box>
        </Box>
      </Box>

      <Drawer
        id="userDetail"
        onClose={onCloseDrawer}
        isOpen={isDrawerOpen}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent
          alignItems={"center"}
          style={{ borderRadius: "15px 0 0 15px" }}
          allowMouseWheel
        >
          <DrawerCloseButton />
          <DrawerBody>
            <UserDetail
              companyId={companyId}
              onCustomerUpdated={fetchCustomer}
              onClose={onCloseDrawer}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ManageUser;
