import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "../components/controls/DatePicker";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const UpdateUserForm = ({ data, onClose }) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [employees, setEmployees] = useState([]);
  const [age, setAge] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formData, setFormData] = useState({
    jobs_id: "",
    team_id: "",
    role_id: "",
    employee_name: "",
    date_of_birth: "",
    age: "",
    mobile_number: "",
    npwp_number: "",
    gender: "",
    religion: "",
    address: "",
    identity_number: "",
    username: "",
    password: "",
  });

  const employeeId = localStorage.getItem("id");

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getAllEmp = axios.get(
          `${process.env.REACT_APP_API_URL}/api/employees/${employeeId}`
        );
        const getAllJobs = axios.get(
          `${process.env.REACT_APP_API_URL}/api/jobs`
        );
        const getAllTeam = axios.get(
          `${process.env.REACT_APP_API_URL}/api/teams`
        );
        const getAllRole = axios.get(
          `${process.env.REACT_APP_API_URL}/api/roles`
        );

        const [jobs, teams, roles, employees] = await Promise.all([
          getAllJobs,
          getAllTeam,
          getAllRole,
          getAllEmp,
        ]);

        const jobsData = jobs.data.data;
        const teamsData = teams.data.data;
        const rolesData = roles.data.data;
        const empData = employees.data.data;

        setJobs(jobsData);
        setTeams(teamsData);
        setRoles(rolesData);
        setEmployees(empData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const handleInputChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });

    if (
      fieldName === "confirmPassword" &&
      formData.password !== e.target.value
    ) {
      setPasswordMatchError("Passwords do not match");
    } else {
      setPasswordMatchError("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "job") {
      const selectedJobId = value;
      setSelectedJob(selectedJobId);
      setFormData({
        ...formData,
        jobs_id: selectedJobId,
      });
    } else if (name === "role") {
      const selectedRoleId = value;
      setSelectedRole(selectedRoleId);
      setFormData({
        ...formData,
        role_id: selectedRoleId,
      });
    } else if (name === "team") {
      const selectedTeamId = value;
      setSelectedTeam(selectedTeamId);
      setFormData({
        ...formData,
        team_id: selectedTeamId,
      });
    } else if (name === "gender") {
      console.log("Selected Gender:", value);
      setFormData({
        ...formData,
        gender: value,
      });
    } else if (name === "religion") {
      console.log("Selected Religion:", value);
      setFormData({
        ...formData,
        religion: value,
      });
    }
  };

  const handleDateChange = (date) => {
    let ageDiff;
    // Mengambil tahun, bulan, dan tanggal dari objek Date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Menggabungkan tahun, bulan, dan tanggal menjadi format 'yyyy-MM-dd'
    const formattedDate = `${year}-${month}-${day}`;

    // Melakukan sesuatu dengan tanggal yang diformat
    console.log("Selected Date:", formattedDate);

    // Update state selectedDate dan formData.date_of_birth
    setSelectedDate(formattedDate);

    if (date) {
      const today = new Date();
      const birthDate = new Date(date);

      ageDiff = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        ageDiff--;
      }

      setAge(ageDiff);
    } else {
      setAge(null);
    }

    // Update form data
    setFormData({
      ...formData,
      date_of_birth: date ? formattedDate : null,
      age: date ? ageDiff.toString() : null,
    });
  };

  const [expandedSections, setExpandedSections] = useState([
    "personalInfo",
    "accountSetting",
    "additionalInfo",
  ]);

  const handleToggleDetails = (value) => {
    if (expandedSections.includes(value)) {
      // Collapse the section if it's currently expanded
      setExpandedSections(
        expandedSections.filter((section) => section !== value)
      );
    } else {
      // Expand the section and keep the default expanded sections
      setExpandedSections([...expandedSections, value]);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const updatedData = {
      ...formData,
    };

    if (
      !updatedData.role_id ||
      updatedData.role_id === data.role_id
    ) {
      updatedData.role_id = data.role_id;
    }
    if (
      !updatedData.jobs_id ||
      updatedData.jobs_id === data.jobs_id
    ) {
      updatedData.jobs_id = data.jobs_id;
    }
    if (
      !updatedData.team_id ||
      updatedData.team_id === data.team_id
    ) {
      updatedData.team_id = data.team_id;
    }
    if (
      !updatedData.employee_name ||
      updatedData.employee_name === data.employee_name
    ) {
      updatedData.employee_name = data.employee_name;
    }
    if (
      !updatedData.date_of_birth ||
      updatedData.date_of_birth === data.date_of_birth
    ) {
      updatedData.date_of_birth = data.date_of_birth;
    }
    if (
      !updatedData.date_of_birth ||
      updatedData.date_of_birth === data.date_of_birth
    ) {
      updatedData.date_of_birth = data.date_of_birth;
    }
    if (
      !updatedData.date_of_birth ||
      updatedData.date_of_birth === data.date_of_birth
    ) {
      updatedData.date_of_birth = data.date_of_birth;
    }
    if (
      !updatedData.age ||
      updatedData.age === data.age
    ) {
      updatedData.age = data.age;
    }
    if (
      !updatedData.gender ||
      updatedData.gender === data.gender
    ) {
      updatedData.gender = data.gender;
    }   
    if (
      !updatedData.religion ||
      updatedData.religion === data.religion
    ) {
      updatedData.religion = data.religion;
    }
    if (!updatedData.mobile_number || updatedData.mobile_number === data.mobile_number) {
      updatedData.mobile_number = data.mobile_number;
    }
    if (
      !updatedData.npwp_number ||
      updatedData.npwp_number === data.npwp_number
    ) {
      updatedData.npwp_number = data.npwp_number;
    }
    if (
      !updatedData.identity_number ||
      updatedData.identity_number === data.identity_number
    ) {
      updatedData.identity_number = data.identity_number;
    }
    if (
      !updatedData.username ||
      updatedData.username === data.username
    ) {
      updatedData.username = data.username;
    }
    if (
      !updatedData.password ||
      updatedData.password === data.password
    ) {
      updatedData.password = data.password;
    }
    if (
      !updatedData.address ||
      updatedData.address === data.address
    ) {
      updatedData.address = data  .address;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/employees/${employeeId}`,
        updatedData
      );

      console.log("Password successfully updated:", response.data);

      setFormData(
        updatedData
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: response.data.message,
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred. Please try again.",
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFormSubmit();
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleClose();
      }}
    >
      <div>
        <p
          className="text-black font-bold flex-row flex items-center gap-3 mb-2"
          onClick={() => handleToggleDetails("personalInfo")}
        >
          Personal Information{" "}
          {expandedSections.includes("personalInfo") ? (
            <>
              <MdKeyboardArrowDown className="w-6 h-6" />
            </>
          ) : (
            <>
              <MdKeyboardArrowRight className="w-6 h-6" />
            </>
          )}
        </p>
      </div>
      {expandedSections.includes("personalInfo") && (
        <div className="row">
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Name</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="employee_name"
                className="form-control"
                value={data.employee_name}
                disabled
                onChange={(e) => handleInputChange(e, "employee_name")}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Email</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="email"
                className="form-control"
                onChange={(e) => handleInputChange(e, "email")}
                value={formData.email}
                placeholder={data.email}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Date Of Birth</label>
            </div>
            <div className="w-full flex-row flex items-center gap-2">
              <Datepicker  required
                  selectedDate={selectedDate}
                  onDateChange={handleDateChange}
                  maxDate={new Date()}
                  className="form-control"
                  placeHolder={data.date_of_birth}/>
              <label className="text-black font-normal">Age</label>

              <input
                type="text"
                id="age"
                className="form-control w-1/3"
                placeholder={data.age}
                value={formData.age}
                onChange={(e) => handleInputChange(e, "age")}
                disabled
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Gender</label>
            </div>
            <div className="w-full">
              <select
                className="form-select"
                name="gender"
                onChange={handleChange}
                value={formData.gender}
                required
              >
                <option value="" disabled>
                  {data.gender}
                </option>
                <option value="" disabled>
                  Choose Gender :
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Religion</label>
            </div>
            <div className="w-full">
              <select
                className="form-select"
                name="religion"
                onChange={handleChange}
                value={formData.religion}
                required
              >
                <option value="" disabled>
                  {data.religion}
                </option>
                <option value="" disabled>
                  Select Religion
                </option>
                <option value="Islam">Islam</option>
                <option value="Kristen">Kristen</option>
                <option value="Katolik">Katolik</option>
                <option value="Buddha">Buddha</option>
                <option value="Kong Hu Cu">Kong Hu Cu</option>
                <option value="Hindu">Hindu</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Mobile Number</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="mobile_number"
                className="form-control"
                placeholder={data.mobile_number}
                value={formData.mobile_number}
                onChange={(e) => handleInputChange(e, "mobile_number")}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Address</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="address"
                className="form-control"
                placeholder={data.address}
                value={formData.address}
                onChange={(e) => handleInputChange(e, "address")}
              />
            </div>
          </div>
        </div>
      )}

      <div>
        <p
          className="text-black font-bold flex-row flex items-center gap-3 mb-2"
          onClick={() => handleToggleDetails("accountSetting")}
        >
          Account Setting{" "}
          {expandedSections.includes("accountSetting") ? (
            <>
              <MdKeyboardArrowDown className="w-6 h-6" />
            </>
          ) : (
            <>
              <MdKeyboardArrowRight className="w-6 h-6" />
            </>
          )}
        </p>
      </div>
      {expandedSections.includes("accountSetting") && (
        <div className="row">
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Username</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="username"
                className="form-control"
                placeholder={data.username}
                value={formData.username}
                onChange={(e) => handleInputChange(e, "username")}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">New Password</label>
            </div>
            <div className="w-full">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                className="form-control"
                placeholder="Enter New Password"
                value={formData.password}
                onChange={(e) => handleInputChange(e, "password")}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              
            </div>
            <div className="w-full">  
              <button
                type="button"
                className=" text-black cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <div className="inline-flex items-center gap-3"><FaRegEye/><p>Show Password</p></div> : <div className="inline-flex items-center gap-3"><FaRegEyeSlash/><p>Hide Password</p></div>}
              </button>
            </div>
          </div>
          
        </div>
      )}

      <div>
        <p
          className="text-black font-bold flex-row flex items-center gap-3 mb-2"
          onClick={() => handleToggleDetails("additionalInfo")}
        >
          Additional Information{" "}
          {expandedSections.includes("additionalInfo") ? (
            <>
              <MdKeyboardArrowDown className="w-6 h-6" />
            </>
          ) : (
            <>
              <MdKeyboardArrowRight className="w-6 h-6" />
            </>
          )}
        </p>
      </div>
      {expandedSections.includes("additionalInfo") && (
        <div className="row">
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">Nomor Induk Kependudukan</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="identity_number"
                className="form-control"
                placeholder={data.identity_number}
                value={formData.identity_number}
                onChange={(e) => handleInputChange(e, "identity_number")}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </div>
          </div>
          <div className="flex-row flex items-center mb-2">
            <div className="w-1/3">
              <label className="text-black font-normal">NPWP Number</label>
            </div>
            <div className="w-full">
              <input
                type="text"
                id="npwp_number"
                className="form-control"
                placeholder={data.npwp_number}
                value={formData.npwp_number}
                onChange={(e) => handleInputChange(e, "npwp_number")}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex-row flex justify-between gap-3 mt-3">
        <div></div>
        <button
          type="submit"
          className="bg-[#238FBA]  rounded-full w-[150px] h-[50px] text-white font-normal p-2 items-center"
          onClick={handleFormSubmit}
        >
          <h3 className="">Change Detail</h3>
        </button>
      </div>
    </form>
  );
};

export default UpdateUserForm;
