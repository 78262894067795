import React, { useState } from "react";
import { Card, CardHeader, CardBody, CardFooter, Text, Box, Flex, Icon, Stack } from "@chakra-ui/react";

const StatisticCard = ({ title, value, loading, icon, color }) => {
  return (
    <Card
      // boxShadow='lg'
      h='auto'
      className={`rounded-xl border-white h-full`}
      style={{ background: color }}
    >
      <CardBody>
        <Flex direction={{base: "column", md:"row"}} className="items-center gap-4 p-3 h-full">
          <Icon boxSize={5} as={icon}/>
          <Stack>
            <Text fontWeight={"500"} borderRadius={"3xl"} fontSize={"md"} textAlign={{base:"center", md:"left"}}>{title}</Text>
            <Text fontSize={"40px"} fontWeight={"700"} color={"black"} textAlign={{base:"center", md:"left"}}>
              {loading ? "Loading..." : value}
            </Text>
          </Stack>
        </Flex>
      </CardBody>
    </Card>
  );
};

export default StatisticCard;
