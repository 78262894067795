import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";
import "../App.css";
import { VscSend } from "react-icons/vsc";
import ChatOnTask from "../components/general/ChatOnTask";
import {
  Box,
  Stack,
  Text,
  Textarea,
  CircularProgress,
  CircularProgressLabel,
  Divider,
  Flex,
  Button,
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Switch,
  Checkbox,
  ButtonGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import Datepicker from "../components/controls/DatePicker";
import { RiDeleteBin5Line } from "react-icons/ri";

const UserDetail = ({ companyId, onCustomerUpdated, onClose }) => {
  const [company, setCompany] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const cancelRef = React.useRef();
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure();
  const [formData, setFormData] = useState({
    company_expired: "",
    total_employee: "",
    verified: null, 
    status: "",
  });

  const fetchCompany = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const company = response.data.data;

      setCompany(company);

      setFormData({
        company_expired: company.company_expired,
        total_employee: company.total_employee,
        verified: company.verified,
        status: company.status,
      });

      setIsLoading(false);
    } catch (error) {
      console.error(
        `Error fetching company details for ID ${companyId}:`,
        error
      );
    }
  };

  useEffect(() => {
    fetchCompany();
  }, [companyId]);

  const getVerifyText = (verified) => {
    switch (verified) {
      case true:
        return "Premium";
      case false:
        return "Free Trial";
      default:
        return "Unknown";
    }
  };

  const getBackgroundColor = (verified) => {
    switch (verified) {
      case true:
        return "#2C4C9C";
      case false:
        return "gray.300";
      default:
        return "Unknown";
    }
  };

  const getTextColor = (verified) => {
    switch (verified) {
      case true:
        return "#B8860B";
      case false:
        return "#2F4F4F";
      default:
        return "Unknown";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSwitchChange = (event, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: event.target.checked,
    });
  };

  const handleSwitchActiveChange = (event, fieldName) => {
    const isChecked = event.target.checked;
    setFormData({
      ...formData,
      [fieldName]: isChecked ? "active" : "inactive", 
    });

    onOpenActive();
  };

  const [selectedExpiredDate, setSelectedExpiredDate] = useState("");

  const handleExpiredDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} 00:00:00`;

    setSelectedExpiredDate(formattedDate);
    setFormData({
      ...formData,
      company_expired: formattedDate,
    });
  };

  const [totalEmployees, setTotalEmployees] = useState(company.total_employee);

  const handleTotalEmployeesChange = (value) => {
    setTotalEmployees(value);
    setFormData({
      ...formData,
      total_employee: value,
    });
  };

  const handleUpdateCompany = async (e) => {
    setIsLoading(true);

    e.preventDefault();

    const updatedData = {
      ...formData,
    };

    if (
      !updatedData.company_expired ||
      updatedData.company_expired === company.company_expired
    ) {
      updatedData.company_expired = company.company_expired;
    }
    if (
      !updatedData.total_employee ||
      updatedData.total_employee === company.total_employee
    ) {
      updatedData.total_employee = company.total_employee;
    }
    if (
      !updatedData.status ||
      updatedData.status === company.status
    ) {
      updatedData.status = company.status;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/update-company/${companyId}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setFormData({
        company_expired: updatedData.company_expired,
        total_employee: updatedData.total_employee,
        verified: updatedData.verified,
        status: updatedData.status,
      });

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: response.data.message,
      }).then(() => {
        fetchCompany();
      }).finally(() => {
        setIsLoading(false);
        onCustomerUpdated && onCustomerUpdated();
        onClose();
      });
      
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred. Please try again.",
        });
      }
    }
    setIsLoading(false);
  };

  const handleDeleteCompany = async (companyId) => {
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 204) {
        Swal.fire({
          icon: "success",
          title: "Perusahaan berhasil dihapus",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      onCustomerUpdated && onCustomerUpdated();
      onClose();
    } catch (error) {
      console.error(`Error deleting company with ID ${companyId}:`, error);
    }
    setIsLoading(false);
    onCloseDelete();
  };

  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleCheckboxChange = () => {
    setConfirmDelete(!confirmDelete);
  };

  const handleResetForm = (reason) => {
    if (reason === "update") {
      setSelectedExpiredDate(null);
      setTotalEmployees("");
      setFormData({
        company_expired: company.company_expired,
        total_employee: company.total_employee,
        verified: company.verified,
        status: company.status,
      });
    } else if (reason === "active") {
      setFormData({
        status: company.status,
      });
      onCloseActive();
    } else {
      onCloseDelete();
    }
  }

 const getShimmerClass = (verified) => {
    return verified ? 'shimmer-gold' : 'shimmer-silver';
  };


  return (
    <Box justifyContent="center" p={"15px"}>
      <Flex direction={"column"} gap={5}>
        <Box>
          <Stack direction="column" gap={2}>
            <Text fontSize="2xl" color="d8d8d8" fontWeight="700">
              Update Pelanggan
            </Text>
            <Flex
              direction="row"
              justifyContent={"space-between"}
              alignItems="center"
            >
              <Stack>
                <Text fontSize="xl" color="black" fontWeight="500">
                  {isLoading ? (
                    <Skeleton height="30px" />
                  ) : (
                    company.company_name
                  )}
                </Text>
                <Text fontSize="md" color="d8d8d8" fontWeight="400">
                  {isLoading ? (
                    <Skeleton height="30px" />
                  ) : (
                    <Text
                      // bg={getBackgroundColor(company.verified)}
                      p={2}
                      borderRadius={"md"}
                      display={"inline-flex"}
                      fontWeight={"600"}
                      color={getTextColor(company.verified)}
                      className={getShimmerClass(company.verified)}
                    >
                      {getVerifyText(company.verified)}
                    </Text>
                  )}
                </Text>
              </Stack>
            </Flex>
          </Stack>
        </Box>
        <Divider opacity="20%" />
        <Box display={"flex"} flexDirection={"column"} gap={5}>
          <Box display={"flex"} flexDirection={"column"} gap={"2em"}>
            <Flex
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack flex={1} spacing={1}>
                <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                  Premium
                </Text>
                <Text fontSize={"sm"} color={"gray.500"}>
                  Perusahaan akan menjadi Premium ketika tombol ini diaktifkan
                </Text>
              </Stack>
              <Box flex={1} display={"flex"} justifyContent={"flex-end"}>
                <Switch
                  size={"lg"}
                  isChecked={formData.verified}
                  onChange={(event) => handleSwitchChange(event, "verified")}
                />
              </Box>
            </Flex>

            <Flex
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                Tanggal Berakhir
              </Text>
              <Box position="relative">
                <Datepicker
                  className="text-black rounded-lg form-control"
                  placeHolder={company.company_expired ? formatDate(company.company_expired) : "Belum Ditentukan"}
                  selectedDate={selectedExpiredDate}
                  onDateChange={handleExpiredDateChange}
                />
              </Box>
            </Flex>

            <Flex
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                Jumlah Maksimal Anggota
              </Text>
              <Box position="relative">
                <NumberInput
                  step={5}
                  min={0}
                  minW={"5em"}
                  maxW={"7em"}
                  value={totalEmployees}
                  onChange={handleTotalEmployeesChange}
                >
                  <NumberInputField placeholder={company.total_employee}/>
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
            </Flex>

            {/* <Flex direction={"column"} gap={3}>
              <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                Reminder Masa Berakhir
              </Text>
              <Stack spacing={3}>
                <Flex
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w="full"
                >
                  <Text fontSize={"md"} color={"black"} fontWeight="400">
                    7 Hari Sebelum Berakhir
                  </Text>
                  <Checkbox size={"lg"} colorScheme="blue" defaultChecked />
                </Flex>
                <Flex
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w="full"
                >
                  <Text fontSize={"md"} color={"black"} fontWeight="400">
                    1 Hari Sebelum Berakhir
                  </Text>
                  <Checkbox size={"lg"} colorScheme="blue" />
                </Flex>
                <Flex
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  w="full"
                >
                  <Text fontSize={"md"} color={"black"} fontWeight="400">
                    Hari-H
                  </Text>
                  <Checkbox size={"lg"} colorScheme="blue" />
                </Flex>
              </Stack>
            </Flex> */}

            <Box display={"flex"} justifyContent={"flex-end"}>
              <ButtonGroup spacing={3}>
                <Button w={"6em"} borderRadius={"full"} bg="gray.300" onClick={()=>handleResetForm("update")}>
                  Batal
                </Button>
                <Button
                  w={"6em"}
                  borderRadius={"full"}
                  bg="#458EBB"
                  color={"white"}
                  onClick={handleUpdateCompany}
                >
                  Simpan
                </Button>
              </ButtonGroup>
            </Box>
          </Box>

          <Divider opacity="20%" />
          <Flex
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack flex={1.5} spacing={1}>
                <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                  {company.status === "active"? "Nonaktifkan Perusahaan Ini?" : "Aktifkan Perusahaan Ini?"}
                </Text>
                <Text fontSize={"sm"} color={"gray.500"}>
                  {company.status === "active"? "Pengguna tidak dapat mengakses akun KejarTugas milik Perusahaan ini." : "Pengguna dapat mengakses akun KejarTugas milik Perusahaan ini."}
                </Text>
              </Stack>
              <Box flex={1} display={"flex"} justifyContent={"flex-end"}>
                <Switch
                  colorScheme="green"
                  size={"lg"}
                  isChecked={formData.status === "active"}
                  onChange={(event) => handleSwitchActiveChange(event, "status")}
                />
              </Box>
            </Flex>

            <AlertDialog
              motionPreset="slideInBottom"
              leastDestructiveRef={cancelRef}
              onClose={onCloseActive}
              isOpen={isOpenActive}
              isCentered
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader>{company.status === "active"? "Nonaktifkan Perusahaan Ini?" : "Aktifkan Perusahaan Ini?"}</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  <Flex direction={"column"} gap={3}>
                    <Text fontSize={"xl"} fontWeight={"600"}>
                      {company.company_name}
                    </Text>
                    {/* <Textarea
                      placeholder="Please input your reason here..."
                      value={textAreaValue}
                      onChange={handleTextChange} 
                    /> */}
                    <Checkbox
                      isChecked={confirmDelete}
                      onChange={handleCheckboxChange}
                      size={"lg"}
                    >
                      {company.status === "active"? "Saya ingin menonaktifkan perusahaan ini." : "Saya ingin mengaktifkan perusahaan ini."}
                    </Checkbox>
                  </Flex>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    w={"6em"}
                    borderRadius={"full"}
                    ref={cancelRef}
                    onClick={()=>handleResetForm("active")}
                  >
                    Batal
                  </Button>
                  <Button
                    isDisabled={!confirmDelete}
                    w={"6em"}
                    borderRadius={"full"}
                    colorScheme="blue"
                    ml={3}
                    onClick={handleUpdateCompany}
                  >
                    Ya
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>

          <Flex direction={"row"} w={"full"} gap={4} alignItems={"center"}>
            <Stack flex={1}>
              <Text fontSize={"16px"} color={"black"} fontWeight={"600"}>
                Hapus Perusahaan Ini?
              </Text>
              <Text fontSize={"sm"} color={"gray.500"}>
                Semua data milik perusahaan ini akan otomatis terhapus
              </Text>
            </Stack>
            <Box flex={1} display={"flex"} justifyContent={"flex-end"}>
              <Button
                bg={"#CF274C"}
                color={"white"}
                fontWeight={"400"}
                borderRadius={"full"}
                _hover={{
                  bg: "red.700",
                }}
                _active={{
                  bg: "#CF274C",
                }}
                onClick={onOpenDelete}
                leftIcon={<RiDeleteBin5Line />}
              >
                Hapus
              </Button>
            </Box>

            <AlertDialog
              motionPreset="slideInBottom"
              leastDestructiveRef={cancelRef}
              onClose={onCloseDelete}
              isOpen={isOpenDelete}
              isCentered
            >
              <AlertDialogOverlay />
              <AlertDialogContent>
                <AlertDialogHeader>Hapus Perusahaan Ini?</AlertDialogHeader>
                <AlertDialogCloseButton />
                <AlertDialogBody>
                  <Flex direction={"column"} gap={3}>
                    <Text fontSize={"xl"} fontWeight={"600"}>
                      {company.company_name}
                    </Text>
                    {/* <Textarea
                      placeholder="Please input your reason here..."
                      value={textAreaValue}
                      onChange={handleTextChange} 
                    /> */}
                    <Checkbox
                      isChecked={confirmDelete}
                      onChange={handleCheckboxChange}
                      size={"lg"}
                    >
                      Saya ingin menghapus perusahaan ini.
                    </Checkbox>
                  </Flex>
                </AlertDialogBody>
                <AlertDialogFooter>
                  <Button
                    w={"6em"}
                    borderRadius={"full"}
                    ref={cancelRef}
                    onClick={onCloseDelete}
                  >
                    Batal
                  </Button>
                  <Button
                    isDisabled={!confirmDelete}
                    w={"6em"}
                    borderRadius={"full"}
                    colorScheme="blue"
                    ml={3}
                    onClick={() => handleDeleteCompany(company.id)}
                  >
                    Ya
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default UserDetail;
