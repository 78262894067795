import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const Datepicker = ({ selectedDate, onDateChange, minDate, maxDate, placeHolder, className }) => {
  const [selected, setSelected] = useState(selectedDate || null); // Set initial state to null
  const customPlaceHolder = placeHolder ? placeHolder : "dd/MM/yyyy";
  
    return (
      <DatePicker
        selected={selected}
        onChange={(date) => {
          setSelected(date);
          onDateChange && onDateChange(date);
        }}
        placeholderText={customPlaceHolder}
        dateFormat={"dd/MM/yyyy"}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        // scrollableYearDropdown
        minDate={minDate}
        maxDate={maxDate}
        className={className}
      /> 
    );
};

export default Datepicker;
