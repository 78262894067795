import React, { useEffect, useState } from "react";
import { BsFillArchiveFill } from "react-icons/bs";
import Linechart from "../components/general/linechart1";
import YearDropdown from "../components/controls/YearPicker";
import { CiSearch } from "react-icons/ci";
import {
  FaEye,
  FaCheck,
  FaTimes,
  FaHome,
  FaAward,
  FaBriefcase,
} from "react-icons/fa";
import "../App.css";
import axios from "axios";
import {
  Switch,
  Select,
  Input,
  Button,
  Editable,
  Textarea,
  Divider,
  Card,
} from "@chakra-ui/react";

function KejarTugasSetting() {
  const [allProjects, setAllProjects] = useState([]);
  const [projectsPerEmployee, setProjectsPerEmployee] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [image, setImage] = useState(null);
  const roleId = localStorage.getItem("role_id");
  const employeeId = localStorage.getItem("id");
  const [formData, setFormData] = useState({
    employee_name: "",
    username: "",
    password: "",
    email: "",
    company_code: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getAllProject = axios.get(
          `${process.env.REACT_APP_API_URL}/api/projects`
        );

        const [project] = await Promise.all([getAllProject]);

        const projectData = project.data.data;

        setAllProjects(projectData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    const fetchProjects = async () => {
      try {
        const projects = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/total-employeeprojects`
        );

        const userProjects = projects.data.employeeProjects.filter(
          (project) => project.employee_id == employeeId
        );

        setProjectsPerEmployee(userProjects);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    if (roleId == 1) {
      fetchData();
    } else if (roleId == 2) {
      fetchProjects();
    }
  }, []);

  const [startYear, setStartYear] = useState(2014);
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [yearPicked, setYearPicked] = useState(false);

  const handleStartYearChange = (year) => {
    setStartYear(year);
  };

  const handleEndYearChange = (year) => {
    setEndYear(year);
    setYearPicked(true);
  };

  const handleFilterButtonClick = () => {
    let projectsToFilter = [];

    if (roleId === "1") {
      projectsToFilter = allProjects;
    } else {
      projectsToFilter = projectsPerEmployee;
    }

    const filteredProjects = projectsToFilter.filter((project) => {
      const projectStartYear = new Date(project.start_date).getFullYear();
      const projectEndYear = new Date(project.end_date).getFullYear();
      const isWithinRange =
        projectStartYear >= startYear && projectEndYear <= endYear;

      return isWithinRange;
    });

    setFilteredProjects(filteredProjects);
  };

  const getCountByStatus = (projectStatus) => {
    let projectsToCount = [];

    if (roleId === "1") {
      projectsToCount = yearPicked ? filteredProjects : allProjects;
    } else if (roleId === "2") {
      projectsToCount = yearPicked ? filteredProjects : projectsPerEmployee;
    }

    return projectsToCount.filter(
      (project) => project.project_status === projectStatus
    ).length;
  };

  const completedProjectCount = getCountByStatus("Completed");
  const onProgressProjectCount = getCountByStatus("workingOnit");
  const getProjectCount = () => {
    if (isLoading) {
      return "Loading...";
    } else if (roleId === "1") {
      return yearPicked ? filteredProjects.length : allProjects.length;
    } else if (roleId === "2") {
      return yearPicked ? filteredProjects.length : projectsPerEmployee.length;
    } else {
      return yearPicked ? allProjects.length : 0;
    }
  };

  const CircularDummyData = [
    { name: "freeTrial", value: 2 },
    { name: "subscription", value: 8 },
  ];

  const originalPieData = [
    { category: "Finish On Time", value: 50 },
    { category: "Finish Early", value: 20 },
    { category: "Finish Delay", value: 15 },
    { category: "Overdue", value: 15 },
  ];

  const dummyData = Array.from({ length: 5 }, (_, index) => ({
    contentId: `${index + 1}`,
    ContentName: `Content ${index + 1}`,
  }));
  
  const pieData = originalPieData.map((item) => ({
    name: item.category, // Use the category as the legend name
    data: item.value, // Use the value as the data for the series
  }));

  const handleInputChange = (e, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const projectCount = getProjectCount();
  return (
    <div className="main-container p-4">
      <main className="main-container">
        <div className="text-gray-600 flex-row flex gap-3 items-center mb-3">
          <a href="/dashboard" className="cursor-pointer">
            <FaHome />
          </a>{" "}
          /
          <a
            href="/kejar-tugas"
            className="hover:text-gray-800 hover:underline cursor-pointer"
          >
            Kejar Tugas
          </a>
        </div>

        <div className="flex-row flex gap-3 mb-3 h-full">
          <Card className="col w-2/3 bg-white rounded-lg p-3 h-auto gap-3">
            <h3 className="text-black font-semibold">EDIT CONTENT</h3>
            <Divider />
            <form className="gap-2 flex-col flex">
              <div className="flex-row flex gap-3">
                <label className="text-black font-semibold w-1/3">
                  Content Type
                </label>
                <Select />
              </div>
              <div className="flex-row flex gap-3">
                <label className="text-black font-semibold w-1/3">
                  Content Headline
                </label>
                <Input
                  id="headline"
                  className="form-control"
                  placeholder="Enter Headline Here..."
                  value={formData.headline}
                  onChange={(e) => handleInputChange(e, "headline")}
                />
              </div>
              <div className="flex-row flex gap-3">
                <label className="text-black font-semibold w-1/3">
                  Content Description
                </label>
                <Textarea
                  id="description"
                  className="form-control"
                  placeholder="Enter Description Here..."
                  rows="5"
                  value={formData.description}
                  onChange={(e) => handleInputChange(e, "description")}
                />
              </div>
              <div className="flex-row flex gap-3">
                <label className="text-black font-semibold w-1/3">
                  Content Image
                </label>
                <Input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="pt-5"
                />
              </div>
            </form>
            <div className="flex-row flex justify-between">
              <div></div>
               <Button>
              <h3>Save Content</h3>
            </Button>
            </div>
          </Card>

          <Card className="w-1/3 bg-white rounded-lg p-3 h-auto gap-3">
            <h3 className="text-black font-semibold">CONTENT TOGGLE</h3>
            <Divider />
            <table className="table table-hover rounded-xl" id="list">
              <thead className="">
                <tr className="font-medium text-[#1B6E8F] align-middle">
                  <th className="" style={{ width: "%" }}>
                    ID
                  </th>
                  <th className=" text-center" style={{ width: "%" }}>
                    Nama
                  </th>
                  <th className=" text-center" style={{ width: "%" }}>
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                {dummyData.map((item, index) => (
                  <tr className="align-middle" key={index}>
                    <td>{item.contentId}</td>
                    <td className="text-center">{item.ContentName}</td>
                    <td className="text-center">
                      <Switch size="sm" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </main>
    </div>
  );
}

export default KejarTugasSetting;
