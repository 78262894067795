import React, {useState} from 'react';
import Chart from 'react-apexcharts';
import "./../../App.css";

const BarChart = ({ data, title, width, height }) => {
  const [activeSeries, setActiveSeries] = useState(null);

  // Extract categories (months) and series data
  const categories = [...new Set(data.map(item => item.name))]; // Unique months
  const subscribedData = [];
  const freeTrialData = [];

  categories.forEach(month => {
    const monthData = data.filter(item => item.name === month);
    subscribedData.push(monthData[0]?.data || 0);
    freeTrialData.push(monthData[1]?.data || 0);
  });

  const series = [
    {
      name: 'Premium',
      data: subscribedData,
    },
    {
      name: 'Free Trial',
      data: freeTrialData,
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: true, // Show the toolbar if needed
      },
    },
    events: {
      legendClick: function(chartContext, seriesIndex, config) {
        const clickedSeries = config.globals.seriesNames[seriesIndex];
        if (activeSeries === clickedSeries) {
          setActiveSeries(null);
        } else {
          setActiveSeries(clickedSeries);
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3, // Adjust the borderRadius value to make the bars rounded
        borderRadiusApplication: 'end', // Apply the borderRadius at the end of the bars
        columnWidth: '40%',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '20px', // Set the desired font size for the values inside the bars
        colors: ['#333'], // Set the color for the data labels
        position: 'top', // Position the labels on top of the bars
      },
      offsetY: -10, // Adjust the vertical offset to move the labels above the bars
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: '#333',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Jumlah Pelanggan',
        style: {
          color: '#333',
        },
      },
      labels: {
        style: {
          colors: '#333',
        },
        formatter: (value) => value.toFixed(0), // Format y-axis labels if needed
      },
      min: 0, // Set minimum value of y-axis
      max: 20, 
      tickAmount: 4, // Approximate number of ticks (5 - 1 = 4 intervals)
      tickPlacement: 'between', // Place ticks between the values
    },
    tooltip: {
      style: {
        fontSize: '14px',
        color: '#000 !important', // Add !important declaration
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    colors: ['#6CE5E8', '#41B8D5'], // Define colors for each series
  };

  const filteredSeries = activeSeries ? series.filter(s => s.name === activeSeries) : series;


  return (
    <Chart options={options} series={filteredSeries} type="bar" height={height} />
  );
};

export default BarChart;
