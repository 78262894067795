import React, { useEffect, useState } from "react";
import { BsFillArchiveFill } from "react-icons/bs";
import Linechart from "../components/general/linechart1";
import YearDropdown from "../components/controls/YearPicker";
import { CiSearch } from "react-icons/ci";
import {
  FaEye,
  FaCheck,
  FaTimes,
  FaHome,
  FaAward,
  FaBriefcase,
} from "react-icons/fa";
import "../App.css";
import axios from "axios";
import StatisticCard from "../components/general/StatisticCard";
import DonutChart from "../components/general/Piecharts";
import {
  Button,
  Card,
  Box,
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Grid,
  SimpleGrid,
  Select,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from "@chakra-ui/react";
import logoKT from "./../assets/logoKT.png";
import logoKTSymbol from "./../assets/logoKT - Symbol.png";
import UserTable from "../components/tables/UserTable";
import Piechart from "../components/general/Piecharts";
import BarChart from "../components/general/BarChart";
import { Verified } from "lucide-react";
import Testing from "../components/general/Testing";
import { TbUserDollar, TbUser } from "react-icons/tb";


function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const roleId = localStorage.getItem("role_id");
  const employeeId = localStorage.getItem("id");
  const [dashboardData, setDashboardData] = useState({});
  const [years, setYears] = useState([]);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const dashboardData = response.data;
      setDashboardData(dashboardData);

      const yearsSet = new Set(
        dashboardData.companies.map((company) =>
          new Date(company.created_at).getFullYear()
        )
      );
      setYears(Array.from(yearsSet).sort());
      console.log("dashboardData:", dashboardData);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchDashboardData();
  }, []);

  const [data, setData] = useState({});
  const [selectedFields, setSelectedFields] = useState([]);
  const fields = [
    {
      label: "Total Pengguna Premium",
      value: "total_member_subscriptions",
      icon: <FaBriefcase />,
      color: "#C4B3FC",
    },
    {
      label: "Total Pengguna Free Trial",
      value: "total_member_not_subscribed",
      icon: <FaAward />,
      color: "#C6E1E1",
    },
    {
      label: "Anggota Premium Kadarluarsa",
      value: "total_member_subscriptionss",
      icon: <BsFillArchiveFill />,
      color: "#F8D3DC",
    },
    {
      label: "Anggota Free Trial Kadaluarsa",
      value: "total_member_not_subscribeds",
      icon: <FaAward />,
      color: "#A4DAF0",
    },
  ];

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    // Mock API call to fetch data
    const result = {
      total_companies_subs: 1,
      total_companies_trial: 2,
      total_member_not_subscribed: 4,
      total_member_subscriptions: 18,
    };
    setData(result);
  };

  const handleFieldChange = (e) => {
    const { value, checked } = e.target;
    setSelectedFields((prev) =>
      checked ? [...prev, value] : prev.filter((field) => field !== value)
    );
  };

  const handleChange = (event) => {
    setBarYear(event.target.value);
  };

  const [barYear, setBarYear] = useState("2024");
  const [error, setError] = useState("");
  const year = barYear;
  const yearData = dashboardData?.data_count?.[year];

  if (!yearData) {
    // Handle the case where the data for the selected year is not available
    console.error(`Data for the year ${year} is not available.`);
    // setError(`Data for the year ${year} is not available.`);
    return null;
  }

  const originalBarData = Object.keys(yearData)
    .map((month) => {
      const monthData = yearData[month];
      return [
        {
          category: `${month}`,
          value: monthData.total_subscribed ?? 0,
        },
        {
          category: `${month}`,
          value: monthData.total_free_trial ?? 0,
        },
      ];
    })
    .flat();

  const barData = originalBarData.map((item) => ({
    name: item.category,
    data: item.value,
  }));

  return (
    <Box 
    p={{base:5, md:7}}
      display={"flex"}
      flexDirection={"column"}
      gap={4}
      className="main-container"
    >
      <Box color={"#333"}>
        {/* <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dashboard">
              <FaHome />
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink href="/dashboard">Dashboard</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb> */}
         <Text color={"black"} fontWeight={"700"} fontSize={{base:"xl", md:"3xl"}}>
          Dashboard
        </Text>
      </Box>

      <Flex direction={{base:"column", md:"row"}} gap={3} w="full">
        <Box
          bg={"white"}
          boxShadow="lg"
          borderRadius={"lg"}
          flex={1}
          w="full"
          p={"1em"}
        >
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text flex={1} color={"black"} fontSize={{base: "lg", md:"xl"}} fontWeight={"600"}>
              Total Pelanggan KejarTugas
            </Text>
            <Box>
              <Select
                color={"black"}
                flex={1}
                value={barYear}
                onChange={handleChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          {error ? (
            <Text color={"red"}>{error}</Text>
          ) : (
            <BarChart
              data={barData}
              title={"Tipe"}
              height={"325px"}
              width={"full"}
            />
          )}
        </Box>
        <Box
          w="full"
          bg={"white"}
          borderRadius={"lg"}
          boxShadow="lg"
          flex={1}
          p={"1em"}
          display={"flex"}
          flexDirection={"column"}
          gap={3}
        >
          <Flex w="full" alignItems={"center"} justifyContent={"space-between"}>
            <Text color={"black"} fontSize={"xl"} fontWeight={"600"}>
              Total Pengguna KejarTugas
            </Text>
            {/* <Popover>
              <PopoverTrigger>
                <Button>Detail</Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverHeader>Header</PopoverHeader>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <div className="text-black">
                      <h2>Pilih Data untuk ditampilkan</h2>
                      {fields.map((field) => (
                        <div key={field.value}>
                          <input
                            type="checkbox"
                            id={field.value}
                            value={field.value}
                            onChange={handleFieldChange}
                          />
                          <label htmlFor={field.value}>{field.label}</label>
                        </div>
                      ))}
                    </div>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover> */}
          </Flex>
          <SimpleGrid columns={2} gap={4}>
            <StatisticCard
              title={"Total Pengguna Premium"}
              loading={isLoading}
              value={dashboardData.total_member_subscriptions}
              icon={TbUserDollar}
              // color={"linear-gradient(to right, #BEADFA, #D0BFFF, #DFCCFB)"}
              color={"#C4B3FC"}
            />
            <StatisticCard
              title={"Total Pengguna Free Trial"}
              loading={isLoading}
              value={dashboardData.total_member_not_subscribed}
              icon={TbUser}
              // color={"linear-gradient(to right, #E3F4F4, #D2E9E9, #C4DFDF )"}
              color={"#C6E1E1"}
            />
            {/* <StatisticCard
              title={"Anggota Premium Kadarluarsa"}
              loading={isLoading}
              value={dashboardData.total_member_subscriptions}
              icon={<BsFillArchiveFill />}
              // color={"linear-gradient(to right, #F2BED1, #FDCEDF, #F2D8D8)"}
              color={"#F8D3DC"}
            />
            <StatisticCard
              title={"Anggota Free Trial Kadaluarsa"}
              loading={isLoading}
              value={dashboardData.total_member_not_subscribed}
              zz
              icon={<FaAward />}
              // color={"linear-gradient(to right, #B9F3FC, #93C6E7)"}
              color={"#A4DAF0"}
            /> */}
          </SimpleGrid>
          {/* {selectedFields.length === 0 ? (
            <p>Please select at least one field to display the data summary.</p>
          ) : (
            <SimpleGrid columns={2} gap={4}>
              {selectedFields.map((field) => {
                const fieldObj = fields.find((f) => f.value === field);
                console.log("Field Object:", fieldObj); // Debugging: log field object
                return (
                  <StatisticCard
                    key={field}
                    title={fieldObj ? fieldObj.label : field}
                    loading={!data[field]}
                    value={data[field]}
                    icon={fieldObj?.icon}
                    color={fieldObj?.color}
                  />
                );
              })}
            </SimpleGrid>
          )} */}
        </Box>
      </Flex>

      <Box borderRadius={"lg"} boxShadow="lg">
        <UserTable data={dashboardData.companies} />
      </Box>
    </Box>
  );
}

export default Home;
