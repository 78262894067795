import React, { useState } from "react";

const SearchInput = ({ onSearchChange, className }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchChange(query); // Call the callback function with the search query
  };

  return (
    <div className="">
      <input
        type="text"
        id=""
        className={className}
        placeholder="Search..."
        value={searchQuery} // Bind input value to searchQuery state
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default SearchInput;
