import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Protected from "./pages/Protected";
import Dashboard from "./pages/dashboard";
import Login from "./pages/loginPage";
import KejarTugas from "./pages/kejarTugasSetting";
import LandingPageSetting from "./pages/landingPageSetting";
import Header from "./components/general/header";
import HeaderLandingPage from "./components/general/headerLandingPage";
import Sidebar from "./components/general/sidebar";
import ManageUser from "./pages/ManageUser";
import LandingPage from "./landing-page/LandingPage";
import "./App.css";
import { ChakraProvider } from '@chakra-ui/react'
import { createTheme, ThemeProvider } from '@mui/material/styles';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const theme = createTheme({
    palette: {
      mode: 'light', // or 'dark'
    },
  });
  
  const handleClick = () => {
    setIsTransitioning(true);
    setIsOpen(!isOpen);
    setTimeout(() => setIsTransitioning(false), 400); // adjust this to match your transition duration
  };

  if (!isAuthenticated) {
    return (
      <ChakraProvider>
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <div className={`grid-container ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
                <Header OpenSidebar={handleClick} isOpen={isOpen} />
                <Sidebar
                  isOpen={isOpen}
                  isTransitioning={isTransitioning}
                  handleClick={handleClick}
                />
                <Protected Page={() => <Dashboard />} />
              </div>
            }
          />
          <Route
            path="/landing-page"
            element={
              <div className={`grid-container ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
                <Header OpenSidebar={handleClick} isOpen={isOpen} />
                <Sidebar
                  isOpen={isOpen}
                  isTransitioning={isTransitioning}
                  handleClick={handleClick}
                />
                <Protected Page={() => <LandingPageSetting />} />
              </div>
            }
          />
           <Route
            path="/kejar-tugas"
            element={
              <div className={`grid-container ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
                <Header OpenSidebar={handleClick} isOpen={isOpen} />
                <Sidebar
                  isOpen={isOpen}
                  isTransitioning={isTransitioning}
                  handleClick={handleClick}
                />
                <Protected Page={() => <KejarTugas />} />
              </div>
            }
          />
          <Route
            path="/manage-user"
            element={
              <div className={`grid-container ${isOpen ? "sidebar-open" : "sidebar-closed"}`}>
                <Header OpenSidebar={handleClick} isOpen={isOpen} />
                <Sidebar
                  isOpen={isOpen}
                  isTransitioning={isTransitioning}
                  handleClick={handleClick}
                />
                <Protected Page={() => <ManageUser />} />
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
      </ChakraProvider>
    );
  }
}

export default App;
